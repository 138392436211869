.Labelstyle {
  font: optional;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  margin-bottom: 0;
  text-transform: capitalize;
}
.button-All{
  border-width: 2px;
  font-weight: 600;
  font-size: 0.8571em;
  line-height: 1.35em;
  text-transform: uppercase;
  border: none;
  margin: 10px 1px;
  border-radius: 3px;
  padding: 11;
  cursor: pointer;
  color: #ffffff;
  transition: all 150ms linear;
  background-color: #fff !important;
  height: 50px;
  width: 100%;
  border: 3px solid Red !important;
  color: red !important;
  font-family: inherit !important;
  &:hover {
    background-color: red !important;
    color: #fff !important;
  }
}
.button-Ongoing{
  border-width: 2px;
  font-weight: 600;
  font-size: 0.8571em;
  line-height: 1.35em;
  text-transform: uppercase;
  border: none;
  margin: 10px 1px;
  border-radius: 3px;
  padding: 11;
  cursor: pointer;
  color: #ffffff;
  transition: all 150ms linear;
  background-color: #fff !important;
  height: 50px;
  width: 100%;
  border: 3px solid green !important;
  color: green !important;
  font-family: inherit !important;
  &:hover {
    background-color: green !important;
    color: #fff !important;
  }
}
.button-Available {
  border-width: 2px;
  font-weight: 600;
  font-size: 0.8571em;
  line-height: 1.35em;
  text-transform: uppercase;
  border: none;
  margin: 10px 1px;
  border-radius: 3px;
  padding: 11;
  cursor: pointer;
  transition: all 150ms linear;
  background-color: #fff !important;
  height: 50px;
  width: 100%;
  border: 3px solid #ffc107 !important;
  color:  #ffc107!important;
  font-family: inherit !important;
  &:hover {
    background-color: #ffc107 !important;
    color: #fff !important;
  }
}

.submitButton {
  background-color: dodgerblue;
  width: "30px";
  color: "#000";
  display: inline;
}
.cancelButton {
  background-color: rgb(233, 16, 16);
  width: "30px";
  color: "#000";
  display: inline;
}
.textlarge {
  font-size: larger;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
}
.text-center {
  text-align: center;
}
.colorbg1 {
  background-color: gray;
  color: #fff;
}
.profileImageriders {
  width: "100%";
  height: "50px";
  border-radius: 20;
  background: #1a98ca;
  font-size: "25px";
  color: #fff;
  text-align: center;
  line-height: 50px;
  margin-left: auto;
  text-transform: uppercase;
}
.ghor //this is the div class
{
  background-image: url("../../img/image_placeholder.jpg");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;

  border-radius: 5px;
  height: 200px;
  width: 200px;
  box-shadow: 2px 5px 7px 7px white;
  /*background-color: black;*/
  display: inline-block;
}
.profileimg {
  background-image: url("../../img/defaultprofile.png");
  border-radius: 50%;
}
.marginzero {
  margin: 0;
  padding: 0;
}
.cardtype1 {
  background-color: "#EFEFEF" !important;
  text-align: "center" !important;
  text-decoration: wavy;
}
///side bar
.sidebar
  .sidebar-wrapper
  > .nav
  [data-toggle="collapse"]
  ~ div
  > ul
  > li
  > a
  .sidebar-normal,
.sidebar
  .sidebar-wrapper
  .user
  .info
  [data-toggle="collapse"]
  ~ div
  > ul
  > li
  > a
  .sidebar-normal,
.off-canvas-sidebar
  .sidebar-wrapper
  > .nav
  [data-toggle="collapse"]
  ~ div
  > ul
  > li
  > a
  .sidebar-normal,
.off-canvas-sidebar
  .sidebar-wrapper
  .user
  .info
  [data-toggle="collapse"]
  ~ div
  > ul
  > li
  > a
  .sidebar-normal {
  white-space: normal !important;
}
